<template>
  <div id="app">
    <v-dialog title="Tips" :visible.sync="dialogVisible" width="30%">
      <span>This is a message</span>
      <span slot="footer">
        <v-btn @click="dialogVisible = false">Cancel</v-btn>
        <v-btn type="primary" @click="dialogVisible = false">Confirm</v-btn>
      </span>
    </v-dialog>

    <v-row class="grey lighten-5 mb-1">
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Division/Branch"
            :hint="`Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean).kode1}`"
            persistent-hint
            single-line
            small-chips
            @change="drawOrgChart"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-flex class="d-flex">
          <OrganizationChart
               id="organization-chart"
               :datasource="orgChartDS"
               style="height: inherit"

          ></OrganizationChart>
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrganizationChart from "vue-organization-chart";

import "element-ui/lib/theme-chalk/index.css";
import "vue-organization-chart/dist/orgchart.css";
import FPegawaiService from "../../../services/apiservices/f-pegawai-service";
import FDivisionService from "../../../services/apiservices/f-division-service";
import { ESalesTypes } from "../../../models/e-sales-type";
import FSalesman from "../../../models/f-salesman";
import FSalesmanService from "@/services/apiservices/f-salesman-service";

export default {
  name: "App",
  components: {
    OrganizationChart
  },
  data() {
    return {
      dialogVisible: false,
      activeId: 0,
      salesmanOfUser: '',

      itemModified: new FSalesman(),
      fdivisionSelected: {},
      itemsFDivision: [],
      itemsFSalesman:[],
      itemsESalesType: ESalesTypes,
      fPegawais:[],

      ds_sample: {
        id: "1",
        name: "Ana",
        title: "General",
        children: [
          { id: "2", name: "Abraham", title: "Lieutenant General" },
        ]
      }

    };
  },

  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fPegawaisFiltered(){
      return this.fPegawais.filter(x => x.statusActive)
    },

    orgChartDS() {
      let dsData = {
        id:0,
        name: this.fdivisionSelected.description,
        title: 'BRANCH/DIVISION',
        children:[]
      }
      let dsRoot=[]
      if (this.fPegawais.length>0) {
        let roots = this.fPegawaisFiltered.filter(x => x.childOf === 0)
        if (this.salesmanOfUser != ''){
          roots = this.fPegawaisFiltered.filter(x => x.id === this.salesmanOfUser.id)
        }

        roots.forEach(item=>{
          let dataChildren = this.fPegawaisFiltered.filter(x=>x.childOf ===item.id)
          const root = {
            id: item.id,
            name: item.spname,
            title: `${this.lookupESalesType(item.salesType)} (${item.spcode})`,
            children: this.createChildren(dataChildren)

          }
          dsRoot.push(root)
        })
      }

      dsData.children = dsRoot //dsRoot With children
      return dsData
    },

  },

  methods:{
    createChildren(data){
      const children =[]
      if (data !==[]){
        data.forEach(item=>{
          let dataChildren = this.fPegawaisFiltered.filter(x=>x.childOf ===item.id)
          const child = {
            id: item.id,
            name: item.spname,
            title: `${this.lookupESalesType(item.salesType)} (${item.spcode})`,
            children: this.createChildren(dataChildren)
          }
          // console.log(JSON.stringify(child.name))
          children.push(child)
        })

      }

      // console.log(`>>> ${JSON.stringify(children)}`)
      return children
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupESalesType (eSalesType) {
      const str = this.itemsESalesType.filter(x => x.id===eSalesType)
      if (str.length>0){
        return `${str[0].description}`
      }else {
        return '-'
      }
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)
      if (str.length>0){
        return `${str[0].spname} *${str[0].spcode}`
      }else {
        return '-'
      }
    },

    drawOrgChart(value){
      // console.log(value)
      this.fetchFPegawai(value)
      this.fdivisionSelected = this.lookupFDivision(value)

    },
    fetchFPegawai(fdivisionBean) {

      // FPegawaiService.getAllFSalesmanByDivision(fdivisionBean).then(
      //     response =>{
      //       this.fPegawais = response.data
      //       console.log(JSON.stringify(response.data))
      //     },
      //     error=>{
      //       console.log(error)
      //     }
      // )

      if (this.currentUser.organizationLevel === "DIV") {
        FPegawaiService.getAllFPegawai().then(
            response =>{
              this.fPegawais = response.data
              // console.log(JSON.stringify(response.data))
            },
            error=>{
              console.log(error)
            }
        )
      }else {
        FPegawaiService.getAllFSalesmanByDivision(fdivisionBean).then(
            response =>{
              this.fPegawais = response.data
              // console.log(JSON.stringify(response.data))
            },
            error=>{
              console.log(error)
            }
        )
      }

    },

    fetchParent(){
      FSalesmanService.getFSalesmanById(this.currentUser.salesmanOf).then(
          response => {
            this.salesmanOfUser = response.data
          }
      )
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
    }
    console.log(this.currentUser.salesmanOf)
  }

};
</script>

<style>
#app .orgchart .node .title {
  background-color: #06C4DE;
}
#app .orgchart .node .content {
  border: 1px solid #06C4DE;
}
#app .orgchart .lines .leftLine {
  border-left: 1px solid #06C4DE;
}
#app .orgchart .lines .rightLine {
  border-right: 1px solid #06C4DE;
}
#app .orgchart .lines .topLine {
  border-top: 1px solid #06C4DE;
}
#app .orgchart .lines .bottomLine {
  border-bottom: 1px solid #06C4DE;
}
#app .orgchart .lines .downLine {
  background-color: #06C4DE;
}

</style>
