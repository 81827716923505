<template>
  <v-card class="mt-2 ml-2 mr-2">
    <OrgChartKomisi/>
  </v-card>
</template>

<script>
import OrgChartKomisi from "../../components/laporan/komisi/OrgChartKomisi";

export default {
  components: {
    OrgChartKomisi
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {

  }
}
</script>

<style scoped>
</style>